<template>
  <div id="supervise_home">
    <!-- <headers :role="role"></headers> -->
    <!-- <PersonalInfo @role="myfn" v-if="false"></PersonalInfo> -->
    <div class="container flex-layout">
        <router-view></router-view>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
// import { mapState, mapMutations } from "vuex";
export default {
  name: "supervise",
  data() {
    return {
      // role: ""
    };
  },
  computed: {},
  components: {},
  methods: {
    // myfn(value) {
    //   this.role = value;
    // },
  },
  mounted() {
   
  },
  created() {}
};
</script>

<style lang="less" scoped>
// @import "./less/home.less";
#supervise_home {
  // min-height: 100vh;
}
.container {
  // flex: 1;
  // background-color: #FAFAFA;
  // padding-top: 60px;
  // position: relative;
  // min-width: 1100px;
}
</style>